// import dependencies
import React from "react";

// import styles and assets
import * as styles from "./styles/post-box.module.scss";

export default function PostBox({children}) {
    return (
        <div className={`profile ${styles.profile}`}>
            {children}
        </div>
    );
}
