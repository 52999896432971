// import dependencies
import React from "react";
import {graphql, Link} from "gatsby";
import Image from "gatsby-plugin-sanity-image";

// import components
import Layout from "../components/layout";
import PostBox from "../components/post-box";
import Section from "../components/section";
import SectionHeader from "../components/section-header";
import SeparatorTopGrey from "../components/separators/separator-top-grey";

// import styles and assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/font-awesome";
import * as styles from "./styles/mercado-laboral.module.scss";

export default function MercadoLaboral({data, pageContext}) {
    return (
<Layout>
    <Section color={"white"}>
        <div className={`container ${styles.intro}`}>
            <SectionHeader color="turquoise">
                <h1>Mercado Laboral</h1>
                <h4>Si eres apoderada o apoderado, te invitamos a descubrir las posibilidades que tu hija o niña a cargo podría tener en un futuro laboral en ciencia y tecnología. Descubre con experiencias reales las posibilidades en estos rubros.</h4>
            </SectionHeader>
            <div className={`row ${styles.merLabRow}`}>
                <div className="col-sm-12">
                    <div className={styles.posts}>
                        {data.allSanityMercadorLaboralPost.edges.map((edge) => (
                            <PostBox key={edge.node.id}>
                                <div className="profilePhoto">
                                    <Image 
                                        {...edge.node.mainImage}
                                        width={320}
                                        height={320}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                        alt="Imágen de mercado laboral" />
                                </div>
                                <div className="profileText">
                                    <h4>{edge.node.title}</h4>
                                    <div className="sep"></div>
                                    <p>{edge.node.excerpt}</p>
                                    <Link to={`/mercado-laboral/${edge.node.slug.current}`}><FontAwesomeIcon icon={["fas", "arrow-right"]} /></Link>
                                </div>
                            </PostBox>
                        ))}
                    </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-12">
                    <Pagination pageContext={pageContext} color="orange" />
                </div>
            </div> */}
        </div>
        <SeparatorTopGrey />
    </Section>
</Layout>
    );
}

export const query = graphql`
query MercadoLaboralPageQuery ($skip: Int!, $limit: Int!) {
    allSanityMercadorLaboralPost (
    sort: {order: DESC, fields: publishedAt}
    skip: $skip,
    limit: $limit) {
        edges {
            node {
                id
                title
                slug {
                    current
                }
                excerpt
                mainImage {
                    ...ImageWithPreview
                }
            }
        }
    }
}
`;